import { createStore } from 'vuex'

import axios from 'axios';

const baseURL = 'https://ares-fc.run/api';
//const baseURL = 'http://localhost:8088/api';

const http = axios.create({
    baseURL: baseURL,
    headers: {
        'Content-Type': 'application/json',
    },
});

export default createStore({
  state: {
  },
  getters: {
  },
  mutations: {
      setUser(state, user) {
          state.user = user;
          state.token = user.token

          localStorage.setItem("user", JSON.stringify(state.user));
      }
  },
  actions: {
    LOGIN({ commit }, { data }) {
      return new Promise((resolve, reject) => {
        http
            .post('/sign-in', data)
            .then(response => {
              commit('setUser', response.data.data);
              resolve(response);
            })
            .catch(error => {
              reject(error.response.data.error);
            });
      });
    },
  },
  modules: {
  }
})
