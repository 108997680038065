<script>
import {useRoute} from 'vue-router';
import CommonHeader from "@/components/common/CommonHeader.vue";
import UserMixin from "@/mixins/UserMixin.vue";
import router from "@/router";

export default {
  data() {
    return {
      showHeader: true,
    }
  },
  mixins: [UserMixin],
  components: {CommonHeader},
  setup() {
    const route = useRoute();
    return {
      currentPath: route.path
    }
  },
  methods: {
    goRoute(path) {
      this.$router.push(path);
    }
  }
}
</script>

<template>
  <header class="bg-black d-flex justify-content-center pt-1 pb-1" v-if="this.$route.meta.showHeader">
    <div class="text-center fw-bold text-white me-auto ms-2">
      <b-dropdown variant="link" toggle-class="text-decoration-none" no-caret>
        <template #button-content>
          <font-awesome-icon :icon="['fas', 'bars']" class="fw-bold"/>
        </template>

        <b-dropdown-group id="dropdown-group-1" header="사용자 메뉴">
        </b-dropdown-group>
        <b-dropdown-group id="dropdown-group-2" header="관리자 메뉴" v-if="this.userInfo.memberGrade > 1">
          <router-link :to="{ path: '/games/attend' }" class="text-decoration-none fw-bold">
            <b-dropdown-item>출석 조회</b-dropdown-item>
          </router-link>
          <router-link :to="{ path: '/members' }" class="text-decoration-none fw-bold">
            <b-dropdown-item>멤버 관리</b-dropdown-item>
          </router-link>
          <router-link :to="{ path: '/teams' }" class="text-decoration-none fw-bold">
            <b-dropdown-item>팀 관리</b-dropdown-item>
          </router-link>
        </b-dropdown-group>
      </b-dropdown>
      <text @click="this.goRoute('/')">ARES</text>
    </div>
  </header>
  <b-container>
    <div class="border shadow-sm rounded">
      <router-view/>
    </div>
  </b-container>
  <!--  <footer class="bg-black text-white fixed-bottom" v-if="this.$route.meta.showFooter">-->
  <!--    <div class="align-middle">-->
  <!--      <ul class="list-unstyled d-flex justify-content-center mb-0 pt-1 pb-1">-->
  <!--        <li class="fs-3">-->
  <!--          <router-link :to="{ path: '/attend' }" class="text-white">-->
  <!--            <font-awesome-icon :icon="['far', 'bell']"/>-->
  <!--          </router-link>-->
  <!--        </li>-->
  <!--      </ul>-->
  <!--    </div>-->
  <!--  </footer>-->

</template>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

nav {
  padding: 30px;
}

nav a {
  font-weight: bold;
  color: #2c3e50;
}

nav a.router-link-exact-active {
  color: #42b983;
}
</style>
